.seats-type {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-top: 32px;
	margin-bottom: 32px;
	&__item {
		display: flex;
		align-items: center;
		color: #4d4d4c;
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
		text-transform: lowercase;
		& + & {
			margin-left: 26px;
		}
		&:before {
			content: "";
			box-sizing: border-box;
			border-radius: 10px;
			display: block;
			width: 30px;
			height: 30px;
			margin-right: 0.25em;
		}
		&:nth-of-type(1):before {
			background-color: #abadaf;
		}
		&:nth-of-type(2):before {
			border: 2px solid #58585b;
		}
		&:nth-of-type(3):before {
			background-color: #744bb7;
		}
	}
}
