.hide {
	&-desktop {
		@include media-breakpoint-up(md) {
			display: none !important;
		}
	}
	&-mobile {
		@include media-breakpoint-down(xs) {
			display: none !important;
		}
	}
}
