.nf-old-route {
	background-color: $color-white;
	box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
	border-radius: custom(border_radius, 10px);
	margin-top: 18px;
	margin-bottom: 24px;
	&.is-disabled {
		opacity: 0.5;
	}
	&.is-individual {
		background-image: url("../images/taxiBg.png");
		background-size: 32px;
		background-position: center;
	}
	&__container {
		padding: 15px 25px 15px;
		display: flex;
		justify-content: space-between;
		@media screen and (max-width: 365px) {
			padding: 5px 10px 5px;
		}
	}
	&__from,
	&__to {
		display: flex;
		flex-direction: column;
	}
	&__main {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 10px 0;
	}
	&__content {
		text-align: center;
		padding: 0 20px;
		width: 100%;
	}
	&__line {
		width: 100%;
		height: 2px;
		background-color: custom(primary_color, #73b3ff);
	}
	&__bus {
		width: 28px;
		flex: 0 0 28px;
		height: 17px;
		margin-right: 16px;
		margin-left: 16px;
		color: custom(primary_color, #73b3ff);
		.is-individual & {
			height: 28px;
		}
	}
	&__time {
		margin-bottom: 5px;
		text-align: center;
		color: $color-black;
		font-size: 30px;
		@media screen and (max-width: 345px) {
			font-size: 25px;
		}
	}
	&__place {
		color: #8a8a8a;
		font-size: 18px;
		text-align: center;
		@include media-breakpoint-down(xs) {
			font-size: 12px;
		}
	}
	&__duration {
		font-size: 14px;
		color: $color-black;
	}
	&__footer {
		position: relative;
		min-height: 50px;
		border-top: 2px dashed custom(primary_color, #73b3ff);
		display: flex;
		flex-wrap: wrap;
		justify-content: center;
		align-items: center;
		background-color: #d9ebff;
		border-radius: 0 0 custom(border_radius, 5px) custom(border_radius, 5px);
		.busRow {
			display: none;
			width: 100%;
			flex: 0 0 100%;
			padding-top: 32px;
			padding-bottom: 16px;
		}
		.amenities {
			display: none;
		}
	}
	&__price {
		color: custom(primary_color, #73b3ff);
		font-weight: 700;
		font-size: 30px;
		&-text {
			color: #8a8a8a;
			font-size: 14px;
		}
	}
	&__btn {
		cursor: pointer;
		@extend %unbutton;
		position: absolute !important;
		padding-right: 10px;
		padding-top: 13px;
		display: flex;
		justify-content: flex-end;
		top: 0;
		bottom: 0;
		left: 0;
		right: 0;
		width: 100%;
	}
	&__arrow {
		display: block;
		color: custom(primary_color, #bedcff) !important;
		width: 30px;
		flex: 0 0 30px;
		height: 30px;
	}
	&__currency {
		font-size: 12px;
		margin-left: 5px;
	}
	&__date {
		margin-top: 5px;
		color: #000;
		font-size: 14px;
		text-align: center;
	}

	.continue-block {
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 16px;
		&__pleaceNum {
			font-size: 18px;
			color: #8d8f93;
		}
		&__btn {
			background-color: custom(button_color, seagreen);
			border-radius: custom(border_radius,10px);
			color: custom(font_color, #fff);
			font-family: raleway, sans-serif;
			font-size: custom(font_size, 18px);
			font-weight: 700;
			line-height: 48px;
			padding: 0 24px;
			text-transform: uppercase;
			cursor: pointer;
			* + & {
				margin-left: 16px;
			}
		}
	}
	
	.seats-count {
		display: none;
	}
}
