@mixin no-scrollbars {
	-ms-overflow-style: none;
	scrollbar-width: none;
	&::-webkit-scrollbar {
		display: none;
	}
}

%no-scrollbars {
	@include no-scrollbars;
}

%custom-input {
	border-radius: 3px;
	width: 100%;
	line-height: 43px;
	height: 45px;
	font-size: inherit;
	color: inherit;
	background-color: transparent;
	border: 1px solid currentColor;
	padding-left: 10px;
	padding-right: 10px;
	box-sizing: border-box;
	font-family: Arial, Helvetica, sans-serif;
	cursor: pointer;
}