.eva-iframe {
    --color-gray: #636b76;
    --color-gray1: #dde6ed;
    --color-green: #489b60;
    --color-blue: #0574bf;
    position: relative;
    font-family: "Montserrat", system-ui;
    font-optical-sizing: auto;
    width: 100%;
    max-width: 350px;
    &-station {
        background: $color-white;
        border-radius: 28px;
        padding-top: 16px;
        padding-bottom: 16px;
        &-item {
            display: flex;
            align-items: center;
            padding-left: 24px;
            padding-right: 24px;
            .icon {
                display: block;
                width: 32px;
                flex: 0 0 32px;
                height: 32px;
                margin-right: 8px;
            }
            &__input {
                width: 100%;
                opacity: 0;
                height: 43px;
                &::placeholder {
                    color: transparent;
                }
                &-wrapper {
                    position: relative;
                    width: 100%;
                    flex: 1 0 0%;
                }
                ~ .select2 {
                    .select2-selection {
                        border: none;
                        padding-top: 20px;
                        height: 43px;
                        background-color: #fff !important;
                        &__rendered {
                            line-height: 20px !important;
                            padding: 0 !important;
                        }
                        &__arrow {
                            display: none;
                        }
                    }
                    &.selected ~ label {
                        top: 0;
                    }
                }
            }
            &__label {
                display: block;
                position: absolute;
                top: 11.5px;
                left: 0;
                pointer-events: none;
                margin-bottom: 0;
                color: var(--color-gray);
                font-weight: 500;
                font-size: 16px;
                line-height: (20/16);
                transition: 0.25s top;
            }
        }
        &-separator {
            position: relative;
            display: flex;
            justify-content: flex-end;
            pointer-events: none;
            margin-top: -6px;
            margin-bottom: -6px;
            &:before {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                left: 0;
                right: 0;
                transform: translateY(-50%);
                height: 2px;
                background-color: var(--color-gray1);
            }
            &__action {
                position: relative;
                display: block;
                cursor: pointer;
                outline: none;
                background: none;
                border: none;
                margin-right: 55px;
                padding-right: 4px;
                padding-left: 4px;
                background-color: $color-white;
                pointer-events: auto;
                .icon {
                    display: block;
                    width: 40px;
                    height: 40px;
                }
            }
        }
    }
    &-options {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        background-color: $color-white;
        border-radius: 28px;
        margin-top: 8px;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 50%;
            transform: translateX(-50);
            width: 2px;
            background-color: var(--color-gray1);
        }
        &:after {
            content: "";
            display: block;
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50);
            height: 2px;
            background-color: var(--color-gray1);
        }
        &-item {
            display: flex;
            align-items: center;
            width: 50%;
            flex: 0 0 50%;
            height: 79px;
            padding-left: 8px;
            padding-right: 8px;
            box-sizing: border-box;
            &:nth-chil(n + 2) {
                margin-top: 2px;
            }
            & > .icon {
                display: block;
                width: 32px;
                flex: 0 0 32px;
                height: 32px;
                margin-right: 8px;
            }
            &__input {
                display: block;
                width: 100%;
                font-size: 18px;
                line-height: 23px;
                padding: 0;
                letter-spacing: 0.01em;
                border: none;
                box-sizing: border-box;
                font-family: inherit;
                &:focus {
                    outline: none;
                }
                &[type="time"] {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    font-size: 28px;
                    line-height: 36px;
                    padding-top: 18px;
                    background-color: $color-white;
                    &::-webkit-calendar-picker-indicator {
                        background: none;
                        cursor: pointer;
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        padding: 0;
                        margin: 0;
                    }
                    ~ label {
                        top: 0;
                    }
                }
                &-wrapper {
                    position: relative;
                    width: 100%;
                    flex: 1 0 0%;
                }
            }
            &__label {
                display: block;
                position: absolute;
                top: 11.5px;
                left: 0;
                pointer-events: none;
                margin-bottom: 0;
                color: var(--color-gray);
                font-weight: 500;
                font-size: 14px;
                line-height: (18/14);
                transition: 0.25s top;
            }
        }
        &-countable {
            display: flex;
            align-items: center;
            &__control {
                color: var(--color-green);
                border: none;
                outline: none;
                background: none;
                cursor: pointer;
                padding: 0;
                &[disabled] {
                    color: rgba(6, 6, 6, 0.25);
                }
                .icon {
                    display: block;
                    width: 30px;
                    height: 30px;
                }
            }
            &__input {
                display: block;
                width: 100%;
                flex: 1 0 0%;
                height: 43px;
                outline: none;
                border: none;
                text-align: center;
                font-weight: 500;
                font-size: 28px;
                padding: 0;
                text-decoration: underline;
            }
        }
    }
    &-submit {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-top: 24px;
        border: none;
        outline: none;
        background-color: var(--color-blue);
        box-shadow: 4px 4px 12px 0px #78aeff inset;
        border-radius: 100px;
        height: 48px;
        width: 100%;
        color: $color-white;
        font-size: 18px;
        font-weight: 500;
        text-transform: uppercase;
        cursor: pointer;
        .icon {
            display: block;
            width: 32px;
            flex: 0 0 32px;
            height: 32px;
            margin-right: 16px;
            margin-left: -48px;
        }
    }
    .hidden {
        display: none;
    }
    > .select2-container {
            position: static !important;
        .select2-dropdown {
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            color: #010813;
            border-radius: 28px!important;
            border: none;
            padding: 10px;
            box-shadow: 0 1rem 3rem rgba(0,0,0,.175)!important;
            .select2-search--dropdown .select2-search__field {
                border-color: #dde6ed;
                background-color: #fff;
                outline: none !important;
            }
            .select2-results__option--selectable {
                background-color: transparent;
                &.select2-results__option--highlighted {
                    background-color: #0574bf;
                    color: #fff;
                }
                &.select2-results__option--selected {
                    display: none;
                }
            }
        }
    }
}

.reservationsWindWrapp_embedded {
    padding: 0;
    max-width: none;
    margin: 0;
    form {
        padding: 0;
    }
}
