.reservationsWindWrapp {
	position: relative;
	.datepicker.dropdown-menu {
		top: 50% !important;
		left: 50% !important;
		transform: translate(-50%, -50%) !important;
		right: auto !important;
		bottom: auto !important;
		min-width: 306px !important;
		background-color: custom(primary_color, #575f6a) !important;
		border-color: custom(font_color, rgba(0, 0, 0, 0.2)) !important;
		border-radius: custom(border_radius, 5px) !important;
		&:before,
		&:after {
			display: none !important;
		}
	}
}
