.nf-dates {
	overflow: hidden;
	padding-bottom: 6px;
	margin-bottom: 10px;
	@include media-breakpoint-down(xs) {
		margin-left: -16px;
		margin-right: -16px;
	}
	&-carousel {
		position: relative;
		box-shadow: 0 1px 4px #0003;
		// border-radius: custom(border_radius, 0);
		.swiper-slide {
			display: flex;
			align-items: flex-end;
			height: auto;
		}
		.swiper-button-prev,
		.swiper-button-next {
			cursor: pointer;
			position: absolute;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			background-color: #e0e1e3;
			width: 26px;
			height: 52px;
			bottom: 8px;
			z-index: 2;
			border-bottom-right-radius: 52px;
			border-top-right-radius: 52px;
			@include media-breakpoint-down(xs) {
				top: 50%;
				transform: translateY(-50%);
				width: 18px;
				height: 36px;
				bottom: auto;
				border-bottom-right-radius: 36px;
				border-top-right-radius: 36px;
			}
			&.swiper-button-disabled {
				opacity: 0.2;
				pointer-events: none;
			}
			svg {
				display: block;
				width: 16px;
				height: 16px;
				path {
					fill: #166bc8;
				}
			}
		}
		.swiper-button-prev {
			left: 0;
		}
		.swiper-button-next {
			transform: scaleX(-1);
			right: 0;
			@include media-breakpoint-down(xs) {
				transform: translateY(-50%) scaleX(-1);
			}
		}
	}
	&-item {
		cursor: pointer;
		width: 100%;
		flex: 1 0 0%;
		text-align: center;
		line-height: 1.25;
		padding-top: 5px;
		padding-bottom: 5px;
		background-color: #fff;
		&:first-child {
			// border-top-left-radius: custom(border_radius, 0);
			// border-bottom-left-radius: custom(border_radius, 0);
		}
		&:last-child {
			// border-top-right-radius: custom(border_radius, 0);
			// border-bottom-right-radius: custom(border_radius, 0);
		}
		@include media-breakpoint-down(xs) {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			align-items: center;
			box-sizing: border-box;
			width: 125px;
			flex: 0 0 125px;
			height: 56px;
			padding-top: 8px;
			padding-bottom: 8px;
		}
		& + & {
			border-left: 1px solid #f4f4f4;
		}
		&:not(.is-disabled):hover {
			box-shadow: 0 1px 4px #0003;
		}
		&.is-disabled {
			pointer-events: none;
		}
		&.is-active {
			pointer-events: none;
			box-shadow: 0 1px 4px #0003;
			border-bottom: 4px solid #f1c933;
			position: relative;
    		z-index: 1;
			@include media-breakpoint-down(xs) {
				box-shadow: none;
			}
			@include media-breakpoint-up(md) {
				// border-top-left-radius: custom(border_radius, 0);
				// border-top-right-radius: custom(border_radius, 0);
			}
		}
		&__date {
			font-size: 14px;
			font-weight: 700;
			color: #166bc8;
			@include media-breakpoint-down(xs) {
				font-size: 12px;
				font-weight: 400;
			}
			.nf-dates-item.is-active & {
				font-size: 20px;
				@include media-breakpoint-down(xs) {
					font-size: 12px;
					font-weight: 700;
				}
			}
			.nf-dates-item.is-disabled & {
				color: #c3c7cc;
			}
		}
		&__day {
			font-size: 12px;
			color: #6c7079;
			@include media-breakpoint-down(xs) {
				order: -1;
				margin-right: 0.25em;
				color: #166bc8;
			}
			.nf-dates-item.is-active & {
				font-size: 14px;
				@include media-breakpoint-down(xs) {
					font-size: 12px;
					font-weight: 700;
				}
			}
			.nf-dates-item.is-disabled & {
				@include media-breakpoint-down(xs) {
					color: #c3c7cc;
				}
			}
		}
		&__price {
			font-size: 20px;
			font-weight: 700;
			color: #073590;
			@include media-breakpoint-down(xs) {
				font-size: 14px;
				font-weight: 400;
				width: 100%;
				flex: 0 0 100%;
			}
			.nf-dates-item.is-active & {
				font-size: 30px;
				line-height: 1;
				@include media-breakpoint-down(xs) {
					font-size: 18px;
					font-weight: 700;
				}
			}
		}
		&__disable {
			@include media-breakpoint-down(xs) {
				display: flex;
				justify-content: center;
				width: 100%;
				flex: 0 0 100%;
			}
			&-icon {
				display: inline-block;
				vertical-align: middle;
				width: 18px;
				height: 18px;
				margin: 3.5px 0;
				@include media-breakpoint-down(xs) {
					width: 14px;
					height: 14px;
					margin: 1.75px 0;
				}
			}
		}
	}
}
