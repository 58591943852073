.scheduleBlockWrapper {
	position: relative;
	min-height: 40px;
	&.is-loading {
		.scheduleBlock {
			position: relative;
			&:before {
				content: "";
				display: block;
				position: absolute;
				top: 0;
				bottom: 0;
				left: 0;
				right: 0;
				background-color: rgba(255, 255, 255, 0.8);
				z-index: 2;
				@include media-breakpoint-up(md) {
					right: 16px;
					left: 16px;
				}
			}
			&:not(:empty) {
				+ .sk-fading-circle {
					top: 135px;
					@include media-breakpoint-up(md) {
						top: 185px;
					}
				}
			}
		}
		.sk-fading-circle {
			display: block !important;
			z-index: 3;
		}
	}
}

.nf-routes {
	margin-bottom: 50px;
	@include media-breakpoint-up(md) {
		width: 100%;
		max-width: 914px;
		margin-right: auto;
		margin-left: auto;
	}
}

.nf-route {
	@include media-breakpoint-down(xs) {
		border: 1.5px solid #ab9ddc;
		border-radius: 6px;
		overflow: hidden;
	}
	& + & {
		margin-top: 20px;
		@include media-breakpoint-up(md) {
			margin-top: 30px;
		}
	}
	&.is-disabled {
		opacity: 0.5;
		pointer-events: none;
	}
	&__container {
		position: relative;
		border-bottom: 1px solid #c7d8df;
		background-color: rgba(227, 228, 237, 0.75);
		@include media-breakpoint-up(md) {
			border: 1px solid #c7d8df;
			display: flex;
			border-radius: 6px;
		}
	}
	&__info {
		background-color: #fdfeff;
		@include media-breakpoint-up(md) {
			width: percentage(419/914);
			flex: 0 0 percentage(419/914);
			border-right: 1px solid #c7d8df;
			border-radius: 6px;
			box-sizing: border-box;
			padding-bottom: 30px;
		}
	}
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		height: 44px;
		padding-left: 24px;
		padding-right: 24px;
		border-bottom: 1px solid #b8c6cc;
	}
	&__payment {
		color: #409a95;
		font-size: 16px;
		font-weight: 600;
		line-height: 20px;
	}
	&-comforts {
		display: flex;
		align-items: center;
		&__item {
			display: block;
			width: 22px;
			flex: 0 0 22px;
			height: 22px;
			object-fit: contain;
			& + & {
				margin-left: 8px;
			}
		}
	}
	&__path {
		display: flex;
		justify-content: space-between;
		padding: 12px 24px;
		.is-individual & {
			background-image: url("../images/taxiBg.png");
			background-size: 32px;
			background-position: center;
		}
	}
	&-point {
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		width: 98px;
		flex: 0 0 98px;
		&__time {
			font-size: 26px;
			font-weight: 600;
			line-height: 32px;
			color: #744bb7;
		}
		&__name {
			display: block;
			font-size: 16px;
			font-weight: 600;
			line-height: 20px;
			min-height: 40px;
			color: #aa711c;
		}
		&__date {
			font-size: 16px;
			font-weight: 500;
			line-height: 20px;
			color: #776c44;
		}
	}
	&-transport {
		display: flex;
		flex-direction: column;
		align-items: center;
		border-radius: 20px;
		border: 2px solid #cdcfd7;
		background-color: rgba(221, 223, 232, 0.5);
		width: 108px;
		flex: 0 0 108px;
		height: 109px;
		padding-top: 5px;
		padding-bottom: 6px;
		box-sizing: border-box;
		&__duration {
			font-size: 12px;
			line-height: 15px;
			color: #3d3d40;
		}
		&__image {
			position: absolute;
			display: block;
			width: 100%;
			height: 100%;
			object-fit: contain;
			top: 0;
			left: 0;
			&-wrapper {
				position: relative;
				flex: 1 0 0%;
				width: 100%;
			}
		}
		&-seats {
			display: flex;
			align-items: center;
			&__count {
				display: flex;
				align-items: center;
				font-size: 14px;
				line-height: 12px;
				color: #3d3d40;
				&:after {
					content: "x";
					font-size: 10px;
					line-height: 12px;
					margin-left: 0.5em;
					margin-right: 0.5em;
				}
			}
			&__icon {
				display: block;
				width: 12px;
				flex: 0 0 12px;
				height: 15px;
			}
		}
	}
	&__search {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		height: 30px;
		background-color: #e9f0f3;
		color: #436877;
	}
	&-order {
		background: #c7d8df;
		display: flex;
		> *:first-child {
			width: 100%;
			flex: 1 0 0%;
			@include media-breakpoint-down(xs) {
				box-sizing: border-box;
				margin-right: 6px;
				margin-left: 6px;
			}
			@include media-breakpoint-up(md) {
				padding-left: 24px;
			}
		}
		@include media-breakpoint-down(xs) {
			flex-direction: row-reverse;
		}
		&__action {
			cursor: pointer;
			display: flex;
			align-items: center;
			text-align: center;
			background-color: transparent;
			border: none;
			outline: none;
			height: 40px;
			color: #436877;
			font-size: 16px;
			font-weight: 700;
			line-height: 20px;
			box-sizing: border-box;
			margin: 0;
			padding: 0;
			width: 100%;
			@include media-breakpoint-down(xs) {
				justify-content: center;
				border: 2px solid #cdcfd7;
				background: rgba(116, 75, 183, 0.5);
				color: #fff;
				font-size: 14px;
				line-height: 17px;
				border-radius: 8px;
			}
			@include media-breakpoint-up(md) {
				pointer-events: none;
			}
		}
		&-seats {
			display: flex;
			align-items: center;
			justify-content: center;
			border-left: 1px solid #4a4f52;
			width: 78px;
			flex: 0 0 78px;
			@include media-breakpoint-down(xs) {
				border-right: 1px solid #4a4f52;
			}
			&__count {
				display: flex;
				align-items: center;
				font-size: 20px;
				line-height: 17px;
				color: #000000;
				&:after {
					content: "x";
					font-size: 12px;
					line-height: 15px;
					margin-left: 0.5em;
					margin-right: 0.5em;
				}
			}
			&__icon {
				display: block;
				width: 16px;
				flex: 0 0 16px;
				height: 17px;
			}
		}
		&__cost {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 100%;
			flex: 1 0 0%;
			color: #e13915;
			font-size: 16px;
			line-height: 21px;
			@include media-breakpoint-up(md) {
				border-left: 1px solid #4a4f52;
			}
			strong {
				font-size: 24px;
				font-weight: 600;
				line-height: 21px;
				margin-right: 0.25em;
			}
		}
	}
	&__footer {
		display: flex;
		justify-content: space-between;
		height: 30px;
		background-color: #e9f0f3;
		padding-left: 24px;
		padding-right: 12px;
		@include media-breakpoint-up(md) {
			position: absolute;
			left: 0;
			bottom: 0;
			width: percentage(418/914);
			box-sizing: border-box;
			border-bottom-right-radius: 6px;
		}
	}
	&__company {
		display: flex;
		align-items: center;
		font-size: 14px;
		line-height: 17px;
		color: #436877;
	}
	&__dropdown {
		cursor: pointer;
		display: flex;
		align-items: center;
		font-size: 12px;
		font-weight: 600;
		line-height: 15px;
		color: #436877;
		background-color: transparent;
		outline: none;
		border: none;
		padding: 0;
		margin-left: 12px;
		.icon {
			display: block;
			width: 17px;
			flex: 0 0 17px;
			height: 8px;
			margin-left: 8px;
		}
	}
	&__additional-info {
		box-sizing: border-box;
		@include media-breakpoint-up(md) {
			width: percentage(494/914);
			flex: 0 0 percentage(494/914);
			padding: 14px 50px;
		}
	}
	&-description {
		@include media-breakpoint-down(xs) {
			padding-left: 24px;
			padding-right: 24px;
		}
		@include media-breakpoint-up(md) {
			display: flex;
			height: 100%;
		}
		&__items {
			display: flex;
			flex-direction: column;
			width: 121px;
			flex: 0 0 121px;
			@include media-breakpoint-down(xs) {
				display: none;
			}
		}
		&-item {
			display: flex;
			align-items: center;
			justify-content: center;
			text-align: center;
			background-color: rgba(221, 223, 232, 0.5);
			border: 2px solid #cdcfd7;
			height: 64px;
			border-radius: 16px;
			font-size: 24px;
			font-weight: 600;
			line-height: 21px;
			color: #58585b;
			margin-right: 12px;
			box-sizing: border-box;
			flex: 1 0 0%;
			& + & {
				margin-top: 12px;
			}
		}
		&__ticket {
			position: relative;
			overflow: hidden;
			box-sizing: border-box;
			@include media-breakpoint-up(md) {
				display: flex;
				flex-direction: column;
				justify-content: center;
				padding: 12px;
				width: 100%;
				flex: 1 0 0%;
				border-radius: 16px;
				border: 2px solid #cdcfd7;
			}
			.js_get-bus-row-bus > .seats-choice {
				@include media-breakpoint-down(xs) {
					display: none;
				}
			}
		}
		&-transport {
			height: 100%;
			display: flex;
			flex-direction: column;
			@include media-breakpoint-down(xs) {
				&__image-wrapper,
				&--individual {
					display: none;
				}
			}
			&__image {
				position: absolute;
				left: 0;
				top: 0;
				width: 100%;
				height: 100%;
				object-fit: contain;
				&-wrapper {
					position: relative;
					width: 100%;
					flex: 1 0 0%;
				}
			}
		}
	}
	&-detail {
		display: none;
		margin-top: -1px;
		&-tabs {
			border: 1px solid #e9f0f3;
			&-controls {
				display: flex;
				align-items: center;
				height: 38px;
				padding-left: 24px;
				border-bottom: 1px solid #e9f0f3;
				background-color: #f7f7f8;
				&__item {
					cursor: pointer;
					display: flex;
					align-items: center;
					justify-content: center;
					text-align: center;
					border: none;
					outline: none;
					background-color: transparent;
					color: #8b8b8b;
					font-size: 16px;
					font-weight: 600;
					line-height: 20px;
					padding: 0;
					&.is-active {
						color: #141516;
						text-decoration: underline;
					}
					& + & {
						margin-left: 32px;
					}
				}
			}
			&-container {
				background-color: #ffffff;
				&-item {
					display: none;
					position: relative;
					padding: 21px 24px 32px;
					min-height: 233px;
					box-sizing: border-box;
					&.is-show {
						display: block;
					}
				}
			}
		}
		&__title {
			font-size: 14px;
			font-weight: 600;
			line-height: 17px;
			margin-bottom: 21px;
			color: #141516;
		}
		&__list {
			color: #141516;
			font-size: 14px;
			line-height: 17px;
			li {
				&.separate {
					margin-bottom: 12px;
				}
				&.gray {
					color: #565556;
				}
				& + li {
					margin-top: 8px;
				}
			}
			a {
				color: #0055b8;
				text-decoration: underline;
			}
		}
		&__pages {
			font-size: 14px;
			font-weight: 300;
			line-height: 17px;
			@include media-breakpoint-down(xs) {
				margin-top: 12px;
			}
			@include media-breakpoint-up(md) {
				position: absolute;
				left: 476px;
				bottom: 32px;
			}
			li {
				& + li {
					margin-top: 9px;
				}
			}
			a {
				text-decoration: underline;
				color: #0055b8;
			}
		}
		&-path {
			position: relative;
			border-radius: 6px;
			border: 2px solid #e9f0f3;
			padding: 8px 0 8px 10px;
			width: 100%;
			max-width: 324px;
			box-sizing: border-box;
			@include media-breakpoint-down(xs) {
				margin-bottom: 18px;
			}
			@include media-breakpoint-up(md) {
				position: absolute;
				left: 476px;
				top: 20px;
			}
			&__icon {
				position: absolute;
				display: block;
				width: 14px;
				height: 70px;
				left: 108px;
				top: 50%;
				transform: translateY(-50%);
			}
			&-point {
				display: flex;
				&:first-of-type {
					margin-bottom: 14px;
					.nf-route-detail-path-point__info {
						position: relative;
						&:after {
							content: "";
							display: block;
							position: absolute;
							bottom: -7px;
							left: 0;
							right: 0;
							height: 1px;
							background-color: rgba(#000, 0.1);
						}
					}
				}
				&__datetime {
					width: 100px;
					flex: 0 0 100px;
				}
				&__time {
					font-size: 20px;
					line-height: 24px;
					color: #565556;
				}
				&__date {
					font-size: 14px;
					font-weight: 500;
					line-height: 17px;
					color: #8b8b8b;
				}
				&__info {
					width: 100%;
					flex: 1 0 0%;
					margin-left: 25px;
				}
				&__name {
					color: #141516;
					font-size: 16px;
					font-weight: 500;
					line-height: 19px;
					max-width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
				}
				&__stop {
					font-size: 14px;
					font-weight: 300;
					line-height: 17px;
					max-width: 100%;
					overflow: hidden;
					text-overflow: ellipsis;
				}
			}
		}
		&__stamp {
			position: absolute;
			width: 180px;
			height: 180px;
			top: 20px;
			left: 476px;
			@include media-breakpoint-down(xs) {
				display: none;
			}
		}
	}
	&-alert {
		margin-bottom: 0;
		height: 64px;
		box-sizing: border-box;
		margin-top: 12px;
		@include media-breakpoint-down(xs) {
			margin-bottom: 12px;
		}
	}

	.continue-block {
		@include media-breakpoint-down(xs) {
			padding-bottom: 12px;
			padding-top: 12px;
		}
		&__btn {
			@extend .seats-choice;
		}
	}

	.seats-choice {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		border-radius: 16px;
		border: 2px solid #cdcfd7;
		background: custom(button_color, rgba(116, 75, 183, 0.5));
		color: custom(font_color, #fff);
		height: 64px;
		width: 100%;
		font-size: 16px;
		font-weight: 700;
		line-height: 20px;
		font-family: inherit;
		box-sizing: border-box;
		.js_form-continue & {
			@include media-breakpoint-up(md) {
				margin-top: 12px;
			}
		}
	}

	.seats-count {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		border-radius: 8px;
		border: 1px solid #cdcfd7;
		height: 82px;
		margin: 24px auto 16px;
		width: 115px;
		&__num {
			display: flex;
			align-items: center;
			font-size: 36px;
			line-height: 31px;
			&:after {
				content: "x";
				display: block;
				margin-left: 0.25em;
				margin-right: 0.25em;
				font-family: Inter;
				font-size: 12px;
				line-height: 15px;
			}
		}
		.icon {
			display: block;
			width: 45px;
			flex: 0 0 45px;
			height: 48px;
		}
	}

	.js_orders-count_places {
		display: none;
	}
}
