.alert {
	display: flex;
	align-items: center;
	font-size: 16px;
	position: relative;
	padding: 16px;
	margin-bottom: 16px;
	border: 1px solid transparent;
	border-radius: custom(border_radius,6px);
	&--warning {
		color: #664d03;
		background-color: #fff3cd;
		border-color: #ffecb5;
	}
	&--danger {
		color: #842029;
    	background-color: #f8d7da;
    	border-color: #f5c2c7;
	}
	&--info {
		color: #055160;
		background-color: #cff4fc;
		border-color: #b6effb;
	}
	&__icon {
		display: block;
		width: 1em;
		flex: 0 0 1em;
		height: 1em;
		margin-right: 0.5em;
	}
	&__content {
		width: 100%;
		flex: 1 0 0%;
	}
}
