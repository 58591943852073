table.fr-dashed-borders td, table.fr-dashed-borders th {
	border-style: dashed
}

table.fr-alternate-rows tbody tr:nth-child(2n) {
	background: #f5f5f5
}

table td.fr-highlighted, table th.fr-highlighted {
	border: 1px double red
}

table td.fr-thick, table th.fr-thick {
	border-width: 2px
}

.fr-file {
	position: relative
}

.fr-file::after {
	position: relative;
	content: "\1F4CE";
	font-weight: 400
}

/*span.fr-emoticon {*/
	/*font-weight: 400;*/
	/*font-family: "Apple Color Emoji", "Segoe UI Emoji", NotoColorEmoji, "Segoe UI Symbol", "Android Emoji", EmojiSymbols;*/
	/*display: inline;*/
	/*line-height: 0*/
/*}*/

/*span.fr-emoticon.fr-emoticon-img {*/
	/*font-size: inherit;*/
	/*height: 1em;*/
	/*width: 1em;*/
	/*min-height: 20px;*/
	/*min-width: 20px;*/
	/*display: inline-block;*/
	/*margin: -.2em .15em .2em;*/
	/*line-height: normal;*/
	/*vertical-align: middle*/
/*}*/

.fr-text-gray {
	color: #AAA !important
}

.fr-text-bordered {
	border-top: solid 1px #222;
	border-bottom: solid 1px #222;
	padding: 10px 0
}

.fr-text-spaced {
	letter-spacing: 1px
}

.fr-text-uppercase {
	text-transform: uppercase
}

img.fr-dib {
	display: block;
	float: none;
	vertical-align: top;
	margin-top: 5px;
	margin-bottom: 5px
}

img.fr-dib.fr-fil {
	margin: 5px auto 5px 0;
	left: 5px
}

img.fr-dib.fr-fir {
	margin: 5px 0 5px auto;
	right: 5px
}

img.fr-dii {
	display: inline-block;
	float: none;
	margin-top: 5px;
	margin-bottom: 5px
}

img.fr-dii.fr-fil {
	margin: 15px 15px 15px 0;
	left: 5px;
	float: left
}

img.fr-dii.fr-fir {
	margin: 15px 0 15px 15px;
	right: 5px;
	float: right
}

img.fr-rounded {
	border-radius: 100%;
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box
}

img.fr-bordered {
	border: solid 10px #CCC;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box
}

.fr-video {
	text-align: center;
	position: relative
}

.fr-video > * {
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box;
	max-width: 100%;
	border: 0
}

.fr-video.fr-dvb {
	display: block;
	clear: both
}

.fr-video.fr-dvb.fr-fvl {
	text-align: left
}

.fr-video.fr-dvb.fr-fvr {
	text-align: right
}

.fr-video.fr-dvi {
	display: inline-block
}

.fr-video.fr-dvi.fr-fvl {
	float: left
}

.fr-video.fr-dvi.fr-fvr {
	float: right
}

a.fr-strong {
	font-weight: 700
}

a.fr-green {
	color: green
}

a.fr-view.fr-strong {
	font-weight: 700
}

a.fr-view.fr-green {
	color: green
}

img.fr-view {
	z-index: 3;
	position: relative;
	overflow: auto;
	cursor: pointer
}

img.fr-view.fr-dib {
	display: block;
	float: none;
	vertical-align: top;
	margin-top: 5px;
	margin-bottom: 5px
}

img.fr-view.fr-dib.fr-fil {
	margin: 15px 15px 15px 0;
	left: 5px
}

img.fr-view.fr-dib.fr-fir {
	margin: 15px 0 15px 15px;
	right: 5px
}

img.fr-view.fr-dii {
	display: inline-block;
	float: none;
	margin-top: 5px;
	margin-bottom: 5px
}

img.fr-view.fr-dii.fr-fil {
	margin: 15px 15px 15px 0;
	left: 5px;
	float: left
}

img.fr-view.fr-dii.fr-fir {
	margin: 15px 0 15px 15px;
	right: 5px;
	float: right
}

img.fr-view.fr-rounded {
	border-radius: 100%;
	-moz-border-radius: 100%;
	-webkit-border-radius: 100%;
	-moz-background-clip: padding;
	-webkit-background-clip: padding-box;
	background-clip: padding-box
}

img.fr-view.fr-bordered {
	border: solid 10px #CCC;
	-webkit-box-sizing: content-box;
	-moz-box-sizing: content-box;
	box-sizing: content-box
}