@media (min-width: 992px) {
  .hide-desktop {
    display: none !important;
  }
}
@media (max-width: 991.98px) {
  .hide-mobile {
    display: none !important;
  }
}

.alert {
  display: flex;
  align-items: center;
  font-size: 16px;
  position: relative;
  padding: 16px;
  margin-bottom: 16px;
  border: 1px solid transparent;
  border-radius: var(--custom-border_radius, 6px);
}
.alert--warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert--danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert--info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert__icon {
  display: block;
  width: 1em;
  flex: 0 0 1em;
  height: 1em;
  margin-right: 0.5em;
}
.alert__content {
  width: 100%;
  flex: 1 0 0%;
}

.sk-fading-circle {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 40px;
  height: 40px;
}

.sk-fading-circle .sk-circle {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.sk-fading-circle .sk-circle:before {
  content: "";
  display: block;
  margin: 0 auto;
  width: 15%;
  height: 15%;
  background-color: #333;
  border-radius: 100%;
  -webkit-animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
  animation: sk-circleFadeDelay 1.2s infinite ease-in-out both;
}

.sk-fading-circle .sk-circle2 {
  -webkit-transform: rotate(30deg);
  -ms-transform: rotate(30deg);
  transform: rotate(30deg);
}

.sk-fading-circle .sk-circle3 {
  -webkit-transform: rotate(60deg);
  -ms-transform: rotate(60deg);
  transform: rotate(60deg);
}

.sk-fading-circle .sk-circle4 {
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}

.sk-fading-circle .sk-circle5 {
  -webkit-transform: rotate(120deg);
  -ms-transform: rotate(120deg);
  transform: rotate(120deg);
}

.sk-fading-circle .sk-circle6 {
  -webkit-transform: rotate(150deg);
  -ms-transform: rotate(150deg);
  transform: rotate(150deg);
}

.sk-fading-circle .sk-circle7 {
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}

.sk-fading-circle .sk-circle8 {
  -webkit-transform: rotate(210deg);
  -ms-transform: rotate(210deg);
  transform: rotate(210deg);
}

.sk-fading-circle .sk-circle9 {
  -webkit-transform: rotate(240deg);
  -ms-transform: rotate(240deg);
  transform: rotate(240deg);
}

.sk-fading-circle .sk-circle10 {
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}

.sk-fading-circle .sk-circle11 {
  -webkit-transform: rotate(300deg);
  -ms-transform: rotate(300deg);
  transform: rotate(300deg);
}

.sk-fading-circle .sk-circle12 {
  -webkit-transform: rotate(330deg);
  -ms-transform: rotate(330deg);
  transform: rotate(330deg);
}

.sk-fading-circle .sk-circle2:before {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}

.sk-fading-circle .sk-circle3:before {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

.sk-fading-circle .sk-circle4:before {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}

.sk-fading-circle .sk-circle5:before {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}

.sk-fading-circle .sk-circle6:before {
  -webkit-animation-delay: -0.7s;
  animation-delay: -0.7s;
}

.sk-fading-circle .sk-circle7:before {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}

.sk-fading-circle .sk-circle8:before {
  -webkit-animation-delay: -0.5s;
  animation-delay: -0.5s;
}

.sk-fading-circle .sk-circle9:before {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}

.sk-fading-circle .sk-circle10:before {
  -webkit-animation-delay: -0.3s;
  animation-delay: -0.3s;
}

.sk-fading-circle .sk-circle11:before {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}

.sk-fading-circle .sk-circle12:before {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
}

@-webkit-keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
@keyframes sk-circleFadeDelay {
  0%, 39%, 100% {
    opacity: 0;
  }
  40% {
    opacity: 1;
  }
}
.nf-old-route__btn {
  padding: 0;
  margin: 0;
  border: none;
  background-color: transparent;
}

.nf-tabs-form__group input[type=time], .nf-tabs-form__group input[type=text],
.nf-tabs-form__group input[type=date], .nf-tabs-form__group .select2-container--default .select2-selection--single, .nf-tabs-form__group .select2-container--default.select2-container--disabled .select2-selection--single, .nf-tabs-form__group select {
  border-radius: 3px;
  width: 100%;
  line-height: 43px;
  height: 45px;
  font-size: inherit;
  color: inherit;
  background-color: transparent;
  border: 1px solid currentColor;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
  cursor: pointer;
}

.backg {
  background: var(--custom-background_color, rgba(85, 93, 105, 0.9803921569));
  border-radius: var(--custom-border_radius, 10px);
  padding: 16px;
  color: var(--custom-font_color, #fff);
}

.nf-dates {
  overflow: hidden;
  padding-bottom: 6px;
  margin-bottom: 10px;
}
@media (max-width: 991.98px) {
  .nf-dates {
    margin-left: -16px;
    margin-right: -16px;
  }
}
.nf-dates-carousel {
  position: relative;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
.nf-dates-carousel .swiper-slide {
  display: flex;
  align-items: flex-end;
  height: auto;
}
.nf-dates-carousel .swiper-button-prev,
.nf-dates-carousel .swiper-button-next {
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: #e0e1e3;
  width: 26px;
  height: 52px;
  bottom: 8px;
  z-index: 2;
  border-bottom-right-radius: 52px;
  border-top-right-radius: 52px;
}
@media (max-width: 991.98px) {
  .nf-dates-carousel .swiper-button-prev,
  .nf-dates-carousel .swiper-button-next {
    top: 50%;
    transform: translateY(-50%);
    width: 18px;
    height: 36px;
    bottom: auto;
    border-bottom-right-radius: 36px;
    border-top-right-radius: 36px;
  }
}
.nf-dates-carousel .swiper-button-prev.swiper-button-disabled,
.nf-dates-carousel .swiper-button-next.swiper-button-disabled {
  opacity: 0.2;
  pointer-events: none;
}
.nf-dates-carousel .swiper-button-prev svg,
.nf-dates-carousel .swiper-button-next svg {
  display: block;
  width: 16px;
  height: 16px;
}
.nf-dates-carousel .swiper-button-prev svg path,
.nf-dates-carousel .swiper-button-next svg path {
  fill: #166bc8;
}
.nf-dates-carousel .swiper-button-prev {
  left: 0;
}
.nf-dates-carousel .swiper-button-next {
  transform: scaleX(-1);
  right: 0;
}
@media (max-width: 991.98px) {
  .nf-dates-carousel .swiper-button-next {
    transform: translateY(-50%) scaleX(-1);
  }
}
.nf-dates-item {
  cursor: pointer;
  width: 100%;
  flex: 1 0 0%;
  text-align: center;
  line-height: 1.25;
  padding-top: 5px;
  padding-bottom: 5px;
  background-color: #fff;
}
@media (max-width: 991.98px) {
  .nf-dates-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    box-sizing: border-box;
    width: 125px;
    flex: 0 0 125px;
    height: 56px;
    padding-top: 8px;
    padding-bottom: 8px;
  }
}
.nf-dates-item + .nf-dates-item {
  border-left: 1px solid #f4f4f4;
}
.nf-dates-item:not(.is-disabled):hover {
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
}
.nf-dates-item.is-disabled {
  pointer-events: none;
}
.nf-dates-item.is-active {
  pointer-events: none;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.2);
  border-bottom: 4px solid #f1c933;
  position: relative;
  z-index: 1;
}
@media (max-width: 991.98px) {
  .nf-dates-item.is-active {
    box-shadow: none;
  }
}
.nf-dates-item__date {
  font-size: 14px;
  font-weight: 700;
  color: #166bc8;
}
@media (max-width: 991.98px) {
  .nf-dates-item__date {
    font-size: 12px;
    font-weight: 400;
  }
}
.nf-dates-item.is-active .nf-dates-item__date {
  font-size: 20px;
}
@media (max-width: 991.98px) {
  .nf-dates-item.is-active .nf-dates-item__date {
    font-size: 12px;
    font-weight: 700;
  }
}
.nf-dates-item.is-disabled .nf-dates-item__date {
  color: #c3c7cc;
}
.nf-dates-item__day {
  font-size: 12px;
  color: #6c7079;
}
@media (max-width: 991.98px) {
  .nf-dates-item__day {
    order: -1;
    margin-right: 0.25em;
    color: #166bc8;
  }
}
.nf-dates-item.is-active .nf-dates-item__day {
  font-size: 14px;
}
@media (max-width: 991.98px) {
  .nf-dates-item.is-active .nf-dates-item__day {
    font-size: 12px;
    font-weight: 700;
  }
}
@media (max-width: 991.98px) {
  .nf-dates-item.is-disabled .nf-dates-item__day {
    color: #c3c7cc;
  }
}
.nf-dates-item__price {
  font-size: 20px;
  font-weight: 700;
  color: #073590;
}
@media (max-width: 991.98px) {
  .nf-dates-item__price {
    font-size: 14px;
    font-weight: 400;
    width: 100%;
    flex: 0 0 100%;
  }
}
.nf-dates-item.is-active .nf-dates-item__price {
  font-size: 30px;
  line-height: 1;
}
@media (max-width: 991.98px) {
  .nf-dates-item.is-active .nf-dates-item__price {
    font-size: 18px;
    font-weight: 700;
  }
}
@media (max-width: 991.98px) {
  .nf-dates-item__disable {
    display: flex;
    justify-content: center;
    width: 100%;
    flex: 0 0 100%;
  }
}
.nf-dates-item__disable-icon {
  display: inline-block;
  vertical-align: middle;
  width: 18px;
  height: 18px;
  margin: 3.5px 0;
}
@media (max-width: 991.98px) {
  .nf-dates-item__disable-icon {
    width: 14px;
    height: 14px;
    margin: 1.75px 0;
  }
}

.scheduleBlockWrapper {
  position: relative;
  min-height: 40px;
}
.scheduleBlockWrapper.is-loading .scheduleBlock {
  position: relative;
}
.scheduleBlockWrapper.is-loading .scheduleBlock:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 2;
}
@media (min-width: 992px) {
  .scheduleBlockWrapper.is-loading .scheduleBlock:before {
    right: 16px;
    left: 16px;
  }
}
.scheduleBlockWrapper.is-loading .scheduleBlock:not(:empty) + .sk-fading-circle {
  top: 135px;
}
@media (min-width: 992px) {
  .scheduleBlockWrapper.is-loading .scheduleBlock:not(:empty) + .sk-fading-circle {
    top: 185px;
  }
}
.scheduleBlockWrapper.is-loading .sk-fading-circle {
  display: block !important;
  z-index: 3;
}

.nf-routes {
  margin-bottom: 50px;
}
@media (min-width: 992px) {
  .nf-routes {
    width: 100%;
    max-width: 914px;
    margin-right: auto;
    margin-left: auto;
  }
}

@media (max-width: 991.98px) {
  .nf-route {
    border: 1.5px solid #ab9ddc;
    border-radius: 6px;
    overflow: hidden;
  }
}
.nf-route + .nf-route {
  margin-top: 20px;
}
@media (min-width: 992px) {
  .nf-route + .nf-route {
    margin-top: 30px;
  }
}
.nf-route.is-disabled {
  opacity: 0.5;
  pointer-events: none;
}
.nf-route__container {
  position: relative;
  border-bottom: 1px solid #c7d8df;
  background-color: rgba(227, 228, 237, 0.75);
}
@media (min-width: 992px) {
  .nf-route__container {
    border: 1px solid #c7d8df;
    display: flex;
    border-radius: 6px;
  }
}
.nf-route__info {
  background-color: #fdfeff;
}
@media (min-width: 992px) {
  .nf-route__info {
    width: 45.8424507659%;
    flex: 0 0 45.8424507659%;
    border-right: 1px solid #c7d8df;
    border-radius: 6px;
    box-sizing: border-box;
    padding-bottom: 30px;
  }
}
.nf-route__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 44px;
  padding-left: 24px;
  padding-right: 24px;
  border-bottom: 1px solid #b8c6cc;
}
.nf-route__payment {
  color: #409a95;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
}
.nf-route-comforts {
  display: flex;
  align-items: center;
}
.nf-route-comforts__item {
  display: block;
  width: 22px;
  flex: 0 0 22px;
  height: 22px;
  object-fit: contain;
}
.nf-route-comforts__item + .nf-route-comforts__item {
  margin-left: 8px;
}
.nf-route__path {
  display: flex;
  justify-content: space-between;
  padding: 12px 24px;
}
.is-individual .nf-route__path {
  background-image: url("../images/taxiBg.png");
  background-size: 32px;
  background-position: center;
}
.nf-route-point {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 98px;
  flex: 0 0 98px;
}
.nf-route-point__time {
  font-size: 26px;
  font-weight: 600;
  line-height: 32px;
  color: #744bb7;
}
.nf-route-point__name {
  display: block;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  min-height: 40px;
  color: #aa711c;
}
.nf-route-point__date {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  color: #776c44;
}
.nf-route-transport {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  border: 2px solid #cdcfd7;
  background-color: rgba(221, 223, 232, 0.5);
  width: 108px;
  flex: 0 0 108px;
  height: 109px;
  padding-top: 5px;
  padding-bottom: 6px;
  box-sizing: border-box;
}
.nf-route-transport__duration {
  font-size: 12px;
  line-height: 15px;
  color: #3d3d40;
}
.nf-route-transport__image {
  position: absolute;
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
  top: 0;
  left: 0;
}
.nf-route-transport__image-wrapper {
  position: relative;
  flex: 1 0 0%;
  width: 100%;
}
.nf-route-transport-seats {
  display: flex;
  align-items: center;
}
.nf-route-transport-seats__count {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 12px;
  color: #3d3d40;
}
.nf-route-transport-seats__count:after {
  content: "x";
  font-size: 10px;
  line-height: 12px;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.nf-route-transport-seats__icon {
  display: block;
  width: 12px;
  flex: 0 0 12px;
  height: 15px;
}
.nf-route__search {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 30px;
  background-color: #e9f0f3;
  color: #436877;
}
.nf-route-order {
  background: #c7d8df;
  display: flex;
}
.nf-route-order > *:first-child {
  width: 100%;
  flex: 1 0 0%;
}
@media (max-width: 991.98px) {
  .nf-route-order > *:first-child {
    box-sizing: border-box;
    margin-right: 6px;
    margin-left: 6px;
  }
}
@media (min-width: 992px) {
  .nf-route-order > *:first-child {
    padding-left: 24px;
  }
}
@media (max-width: 991.98px) {
  .nf-route-order {
    flex-direction: row-reverse;
  }
}
.nf-route-order__action {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: transparent;
  border: none;
  outline: none;
  height: 40px;
  color: #436877;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  width: 100%;
}
@media (max-width: 991.98px) {
  .nf-route-order__action {
    justify-content: center;
    border: 2px solid #cdcfd7;
    background: rgba(116, 75, 183, 0.5);
    color: #fff;
    font-size: 14px;
    line-height: 17px;
    border-radius: 8px;
  }
}
@media (min-width: 992px) {
  .nf-route-order__action {
    pointer-events: none;
  }
}
.nf-route-order-seats {
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid #4a4f52;
  width: 78px;
  flex: 0 0 78px;
}
@media (max-width: 991.98px) {
  .nf-route-order-seats {
    border-right: 1px solid #4a4f52;
  }
}
.nf-route-order-seats__count {
  display: flex;
  align-items: center;
  font-size: 20px;
  line-height: 17px;
  color: #000000;
}
.nf-route-order-seats__count:after {
  content: "x";
  font-size: 12px;
  line-height: 15px;
  margin-left: 0.5em;
  margin-right: 0.5em;
}
.nf-route-order-seats__icon {
  display: block;
  width: 16px;
  flex: 0 0 16px;
  height: 17px;
}
.nf-route-order__cost {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  flex: 1 0 0%;
  color: #e13915;
  font-size: 16px;
  line-height: 21px;
}
@media (min-width: 992px) {
  .nf-route-order__cost {
    border-left: 1px solid #4a4f52;
  }
}
.nf-route-order__cost strong {
  font-size: 24px;
  font-weight: 600;
  line-height: 21px;
  margin-right: 0.25em;
}
.nf-route__footer {
  display: flex;
  justify-content: space-between;
  height: 30px;
  background-color: #e9f0f3;
  padding-left: 24px;
  padding-right: 12px;
}
@media (min-width: 992px) {
  .nf-route__footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 45.7330415755%;
    box-sizing: border-box;
    border-bottom-right-radius: 6px;
  }
}
.nf-route__company {
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 17px;
  color: #436877;
}
.nf-route__dropdown {
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 12px;
  font-weight: 600;
  line-height: 15px;
  color: #436877;
  background-color: transparent;
  outline: none;
  border: none;
  padding: 0;
  margin-left: 12px;
}
.nf-route__dropdown .icon {
  display: block;
  width: 17px;
  flex: 0 0 17px;
  height: 8px;
  margin-left: 8px;
}
.nf-route__additional-info {
  box-sizing: border-box;
}
@media (min-width: 992px) {
  .nf-route__additional-info {
    width: 54.0481400438%;
    flex: 0 0 54.0481400438%;
    padding: 14px 50px;
  }
}
@media (max-width: 991.98px) {
  .nf-route-description {
    padding-left: 24px;
    padding-right: 24px;
  }
}
@media (min-width: 992px) {
  .nf-route-description {
    display: flex;
    height: 100%;
  }
}
.nf-route-description__items {
  display: flex;
  flex-direction: column;
  width: 121px;
  flex: 0 0 121px;
}
@media (max-width: 991.98px) {
  .nf-route-description__items {
    display: none;
  }
}
.nf-route-description-item {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: rgba(221, 223, 232, 0.5);
  border: 2px solid #cdcfd7;
  height: 64px;
  border-radius: 16px;
  font-size: 24px;
  font-weight: 600;
  line-height: 21px;
  color: #58585b;
  margin-right: 12px;
  box-sizing: border-box;
  flex: 1 0 0%;
}
.nf-route-description-item + .nf-route-description-item {
  margin-top: 12px;
}
.nf-route-description__ticket {
  position: relative;
  overflow: hidden;
  box-sizing: border-box;
}
@media (min-width: 992px) {
  .nf-route-description__ticket {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 12px;
    width: 100%;
    flex: 1 0 0%;
    border-radius: 16px;
    border: 2px solid #cdcfd7;
  }
}
@media (max-width: 991.98px) {
  .nf-route-description__ticket .js_get-bus-row-bus > .seats-choice, .nf-route-description__ticket .nf-route .js_get-bus-row-bus > .continue-block__btn, .nf-route .nf-route-description__ticket .js_get-bus-row-bus > .continue-block__btn {
    display: none;
  }
}
.nf-route-description-transport {
  height: 100%;
  display: flex;
  flex-direction: column;
}
@media (max-width: 991.98px) {
  .nf-route-description-transport__image-wrapper, .nf-route-description-transport--individual {
    display: none;
  }
}
.nf-route-description-transport__image {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.nf-route-description-transport__image-wrapper {
  position: relative;
  width: 100%;
  flex: 1 0 0%;
}
.nf-route-detail {
  display: none;
  margin-top: -1px;
}
.nf-route-detail-tabs {
  border: 1px solid #e9f0f3;
}
.nf-route-detail-tabs-controls {
  display: flex;
  align-items: center;
  height: 38px;
  padding-left: 24px;
  border-bottom: 1px solid #e9f0f3;
  background-color: #f7f7f8;
}
.nf-route-detail-tabs-controls__item {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: none;
  outline: none;
  background-color: transparent;
  color: #8b8b8b;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  padding: 0;
}
.nf-route-detail-tabs-controls__item.is-active {
  color: #141516;
  text-decoration: underline;
}
.nf-route-detail-tabs-controls__item + .nf-route-detail-tabs-controls__item {
  margin-left: 32px;
}
.nf-route-detail-tabs-container {
  background-color: #ffffff;
}
.nf-route-detail-tabs-container-item {
  display: none;
  position: relative;
  padding: 21px 24px 32px;
  min-height: 233px;
  box-sizing: border-box;
}
.nf-route-detail-tabs-container-item.is-show {
  display: block;
}
.nf-route-detail__title {
  font-size: 14px;
  font-weight: 600;
  line-height: 17px;
  margin-bottom: 21px;
  color: #141516;
}
.nf-route-detail__list {
  color: #141516;
  font-size: 14px;
  line-height: 17px;
}
.nf-route-detail__list li.separate {
  margin-bottom: 12px;
}
.nf-route-detail__list li.gray {
  color: #565556;
}
.nf-route-detail__list li + li {
  margin-top: 8px;
}
.nf-route-detail__list a {
  color: #0055b8;
  text-decoration: underline;
}
.nf-route-detail__pages {
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
}
@media (max-width: 991.98px) {
  .nf-route-detail__pages {
    margin-top: 12px;
  }
}
@media (min-width: 992px) {
  .nf-route-detail__pages {
    position: absolute;
    left: 476px;
    bottom: 32px;
  }
}
.nf-route-detail__pages li + li {
  margin-top: 9px;
}
.nf-route-detail__pages a {
  text-decoration: underline;
  color: #0055b8;
}
.nf-route-detail-path {
  position: relative;
  border-radius: 6px;
  border: 2px solid #e9f0f3;
  padding: 8px 0 8px 10px;
  width: 100%;
  max-width: 324px;
  box-sizing: border-box;
}
@media (max-width: 991.98px) {
  .nf-route-detail-path {
    margin-bottom: 18px;
  }
}
@media (min-width: 992px) {
  .nf-route-detail-path {
    position: absolute;
    left: 476px;
    top: 20px;
  }
}
.nf-route-detail-path__icon {
  position: absolute;
  display: block;
  width: 14px;
  height: 70px;
  left: 108px;
  top: 50%;
  transform: translateY(-50%);
}
.nf-route-detail-path-point {
  display: flex;
}
.nf-route-detail-path-point:first-of-type {
  margin-bottom: 14px;
}
.nf-route-detail-path-point:first-of-type .nf-route-detail-path-point__info {
  position: relative;
}
.nf-route-detail-path-point:first-of-type .nf-route-detail-path-point__info:after {
  content: "";
  display: block;
  position: absolute;
  bottom: -7px;
  left: 0;
  right: 0;
  height: 1px;
  background-color: rgba(0, 0, 0, 0.1);
}
.nf-route-detail-path-point__datetime {
  width: 100px;
  flex: 0 0 100px;
}
.nf-route-detail-path-point__time {
  font-size: 20px;
  line-height: 24px;
  color: #565556;
}
.nf-route-detail-path-point__date {
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #8b8b8b;
}
.nf-route-detail-path-point__info {
  width: 100%;
  flex: 1 0 0%;
  margin-left: 25px;
}
.nf-route-detail-path-point__name {
  color: #141516;
  font-size: 16px;
  font-weight: 500;
  line-height: 19px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nf-route-detail-path-point__stop {
  font-size: 14px;
  font-weight: 300;
  line-height: 17px;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
}
.nf-route-detail__stamp {
  position: absolute;
  width: 180px;
  height: 180px;
  top: 20px;
  left: 476px;
}
@media (max-width: 991.98px) {
  .nf-route-detail__stamp {
    display: none;
  }
}
.nf-route-alert {
  margin-bottom: 0;
  height: 64px;
  box-sizing: border-box;
  margin-top: 12px;
}
@media (max-width: 991.98px) {
  .nf-route-alert {
    margin-bottom: 12px;
  }
}
@media (max-width: 991.98px) {
  .nf-route .continue-block {
    padding-bottom: 12px;
    padding-top: 12px;
  }
}
.nf-route .seats-choice, .nf-route .continue-block__btn {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 16px;
  border: 2px solid #cdcfd7;
  background: var(--custom-button_color, rgba(116, 75, 183, 0.5));
  color: var(--custom-font_color, #fff);
  height: 64px;
  width: 100%;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  font-family: inherit;
  box-sizing: border-box;
}
@media (min-width: 992px) {
  .js_form-continue .nf-route .seats-choice, .js_form-continue .nf-route .continue-block__btn {
    margin-top: 12px;
  }
}
.nf-route .seats-count {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #cdcfd7;
  height: 82px;
  margin: 24px auto 16px;
  width: 115px;
}
.nf-route .seats-count__num {
  display: flex;
  align-items: center;
  font-size: 36px;
  line-height: 31px;
}
.nf-route .seats-count__num:after {
  content: "x";
  display: block;
  margin-left: 0.25em;
  margin-right: 0.25em;
  font-family: Inter;
  font-size: 12px;
  line-height: 15px;
}
.nf-route .seats-count .icon {
  display: block;
  width: 45px;
  flex: 0 0 45px;
  height: 48px;
}
.nf-route .js_orders-count_places {
  display: none;
}

.nf-old-route {
  background-color: #fff;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: var(--custom-border_radius, 10px);
  margin-top: 18px;
  margin-bottom: 24px;
}
.nf-old-route.is-disabled {
  opacity: 0.5;
}
.nf-old-route.is-individual {
  background-image: url("../images/taxiBg.png");
  background-size: 32px;
  background-position: center;
}
.nf-old-route__container {
  padding: 15px 25px 15px;
  display: flex;
  justify-content: space-between;
}
@media screen and (max-width: 365px) {
  .nf-old-route__container {
    padding: 5px 10px 5px;
  }
}
.nf-old-route__from, .nf-old-route__to {
  display: flex;
  flex-direction: column;
}
.nf-old-route__main {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
.nf-old-route__content {
  text-align: center;
  padding: 0 20px;
  width: 100%;
}
.nf-old-route__line {
  width: 100%;
  height: 2px;
  background-color: var(--custom-primary_color, #73b3ff);
}
.nf-old-route__bus {
  width: 28px;
  flex: 0 0 28px;
  height: 17px;
  margin-right: 16px;
  margin-left: 16px;
  color: var(--custom-primary_color, #73b3ff);
}
.is-individual .nf-old-route__bus {
  height: 28px;
}
.nf-old-route__time {
  margin-bottom: 5px;
  text-align: center;
  color: #000;
  font-size: 30px;
}
@media screen and (max-width: 345px) {
  .nf-old-route__time {
    font-size: 25px;
  }
}
.nf-old-route__place {
  color: #8a8a8a;
  font-size: 18px;
  text-align: center;
}
@media (max-width: 991.98px) {
  .nf-old-route__place {
    font-size: 12px;
  }
}
.nf-old-route__duration {
  font-size: 14px;
  color: #000;
}
.nf-old-route__footer {
  position: relative;
  min-height: 50px;
  border-top: 2px dashed var(--custom-primary_color, #73b3ff);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  background-color: #d9ebff;
  border-radius: 0 0 var(--custom-border_radius, 5px) var(--custom-border_radius, 5px);
}
.nf-old-route__footer .busRow {
  display: none;
  width: 100%;
  flex: 0 0 100%;
  padding-top: 32px;
  padding-bottom: 16px;
}
.nf-old-route__footer .amenities {
  display: none;
}
.nf-old-route__price {
  color: var(--custom-primary_color, #73b3ff);
  font-weight: 700;
  font-size: 30px;
}
.nf-old-route__price-text {
  color: #8a8a8a;
  font-size: 14px;
}
.nf-old-route__btn {
  cursor: pointer;
  position: absolute !important;
  padding-right: 10px;
  padding-top: 13px;
  display: flex;
  justify-content: flex-end;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
}
.nf-old-route__arrow {
  display: block;
  color: var(--custom-primary_color, #bedcff) !important;
  width: 30px;
  flex: 0 0 30px;
  height: 30px;
}
.nf-old-route__currency {
  font-size: 12px;
  margin-left: 5px;
}
.nf-old-route__date {
  margin-top: 5px;
  color: #000;
  font-size: 14px;
  text-align: center;
}
.nf-old-route .continue-block {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 16px;
}
.nf-old-route .continue-block__pleaceNum {
  font-size: 18px;
  color: #8d8f93;
}
.nf-old-route .continue-block__btn {
  background-color: var(--custom-button_color, seagreen);
  border-radius: var(--custom-border_radius, 10px);
  color: var(--custom-font_color, #fff);
  font-family: raleway, sans-serif;
  font-size: var(--custom-font_size, 18px);
  font-weight: 700;
  line-height: 48px;
  padding: 0 24px;
  text-transform: uppercase;
  cursor: pointer;
}
* + .nf-old-route .continue-block__btn {
  margin-left: 16px;
}
.nf-old-route .seats-count {
  display: none;
}

.seats-type {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 32px;
  margin-bottom: 32px;
}
.seats-type__item {
  display: flex;
  align-items: center;
  color: #4d4d4c;
  font-size: 16px;
  font-weight: 700;
  line-height: 20px;
  text-transform: lowercase;
}
.seats-type__item + .seats-type__item {
  margin-left: 26px;
}
.seats-type__item:before {
  content: "";
  box-sizing: border-box;
  border-radius: 10px;
  display: block;
  width: 30px;
  height: 30px;
  margin-right: 0.25em;
}
.seats-type__item:nth-of-type(1):before {
  background-color: #abadaf;
}
.seats-type__item:nth-of-type(2):before {
  border: 2px solid #58585b;
}
.seats-type__item:nth-of-type(3):before {
  background-color: #744bb7;
}

.nf-tabs-control {
  display: flex;
  margin-bottom: 4px;
}
@media (max-width: 991.98px) {
  .nf-tabs-control {
    display: none;
  }
}
.nf-tabs-control__item {
  cursor: pointer;
  border-radius: 3px;
  padding: 10px 20px;
  font-size: var(--custom-font_size);
  color: var(--custom-font_color);
  background-color: var(--custom-add-background_color, #ccc);
  border-radius: var(--custom-border_radius);
}
.nf-tabs-control__item.is-active {
  position: relative;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-color: var(--custom-background_color);
}
.nf-tabs-control__item.is-active:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  height: 4px;
  background-color: var(--custom-background_color, currentColor);
}
.nf-tabs-control__item + .nf-tabs-control__item {
  margin-left: 4px;
}
.nf-tabs__container {
  color: var(--custom-font_color, #fff);
  border-radius: 0 var(--custom-border_radius, 3px) var(--custom-border_radius, 3px) var(--custom-border_radius, 3px);
}
@media (max-width: 991.98px) {
  .nf-tabs__container {
    border-radius: var(--custom-border_radius);
  }
}
@media (min-width: 992px) {
  .nf-tabs__container {
    background-color: var(--custom-background_color);
  }
}
.nf-tabs-item {
  display: none;
}
@media (max-width: 991.98px) {
  .nf-tabs-item {
    background-color: var(--custom-add-background_color);
  }
}
.nf-tabs-item-title {
  position: relative;
  padding: 12px;
  font-weight: 700;
  font-size: var(--custom-font_size);
  color: var(--custom-font_color);
}
@media (max-width: 991.98px) {
  .nf-tabs-item-title {
    background-color: var(--custom-background_color);
  }
  .nf-tabs-item-title[data-id=booking] {
    border-top-left-radius: var(--custom-border_radius);
    border-top-right-radius: var(--custom-border_radius);
  }
  .nf-tabs-item-title[data-id=status-booking]:not(.is-active) {
    border-bottom-left-radius: var(--custom-border_radius);
    border-bottom-right-radius: var(--custom-border_radius);
  }
}
@media (min-width: 992px) {
  .nf-tabs-item-title {
    display: none;
  }
}
.nf-tabs-item-title:after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  background: url("../images/arrow-down.png") center no-repeat;
  background-size: contain;
  top: 50%;
  transform: translateY(-50%);
  right: 12px;
  transition: 0.25s transform;
}
@media (max-width: 991.98px) {
  .nf-tabs-item-title.is-active {
    text-align: center;
    font-size: 20px;
  }
  .nf-tabs-item-title.is-active + .nf-tabs-item[data-id=status-booking] {
    border-bottom-left-radius: var(--custom-border_radius);
    border-bottom-right-radius: var(--custom-border_radius);
  }
}
.nf-tabs-item-title.is-active:after {
  transform: translateY(-50%) rotate(180deg);
}
.nf-tabs-item + .nf-tabs-item-title {
  border-top: 1px solid var(--custom-font_color, #fff);
}
.nf-tabs-form {
  position: relative;
  width: 100%;
  flex: 0 0 100%;
}
.nf-tabs-form__row--status {
  display: none !important;
  position: absolute;
  left: 0;
  right: 0;
  background-color: var(--custom-background_color);
  margin-bottom: 0;
  align-items: center;
  top: -15px;
  bottom: -15px;
  border-radius: 0 var(--custom-border_radius) var(--custom-border_radius) var(--custom-border_radius);
}
@media (max-width: 991.98px) {
  .nf-tabs-form__row--status {
    left: -12px;
    right: -12px;
    padding: 15px 12px;
    border-radius: 0 0 var(--custom-border_radius) var(--custom-border_radius);
  }
}
.nf-tabs-form__row--status.is-show {
  display: flex !important;
}
@media (max-width: 991.98px) {
  .nf-tabs-form__row--status.is-show {
    display: block !important;
  }
}
.nf-tabs-form__row--status:after {
  cursor: pointer;
  content: "";
  display: block;
  position: absolute;
  top: 12px;
  right: 12px;
  width: 12px;
  height: 12px;
  background: url("../images/close.png") center no-repeat;
  background-size: contain;
}
.nf-tabs-form__group {
  position: relative;
  font-size: var(--custom-font_size);
  color: var(--custom-font_color);
}
.nf-tabs-form__group--flex {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.nf-tabs-form__group + .nf-tabs-form__group {
  margin-top: 15px;
}
.nf-tabs-form__group select {
  border-radius: var(--custom-border_radius);
}
.nf-tabs-form__group select option {
  background-color: var(--custom-background_color);
}
.nf-tabs-form__group .select2-container--default .select2-selection--single, .nf-tabs-form__group .select2-container--default.select2-container--disabled .select2-selection--single {
  border-radius: var(--custom-border_radius);
}
.nf-tabs-form__group .select2-container--default .select2-selection--single .select2-selection__rendered, .nf-tabs-form__group .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__rendered {
  line-height: inherit;
  color: inherit;
  padding: 0;
}
.nf-tabs-form__group .select2-container--default .select2-selection--single .select2-selection__arrow, .nf-tabs-form__group .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__arrow {
  height: 41px;
}
.nf-tabs-form__group .select2-container--default .select2-selection--single .select2-selection__arrow b, .nf-tabs-form__group .select2-container--default.select2-container--disabled .select2-selection--single .select2-selection__arrow b {
  border-color: var(--custom-font_color) transparent transparent transparent;
}
.nf-tabs-form__group .select2-container--default.select2-container--disabled {
  opacity: 0.5;
}
.nf-tabs-form__group input[type=text],
.nf-tabs-form__group input[type=date] {
  border-radius: var(--custom-border_radius);
  outline: none;
}
.nf-tabs-form__group input[type=date],
.nf-tabs-form__group input[type=time] {
  border-radius: var(--custom-border_radius);
  position: relative;
}
.nf-tabs-form__group input[type=date]::-webkit-calendar-picker-indicator,
.nf-tabs-form__group input[type=time]::-webkit-calendar-picker-indicator {
  background: none;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
}
.nf-tabs-form__group input[type=date]:focus,
.nf-tabs-form__group input[type=time]:focus {
  outline: none;
}
.nf-tabs-form__group input::placeholder {
  color: var(--custom-font_color) !important;
}
.nf-tabs-form__group table {
  border-collapse: collapse;
  line-height: 22.5px;
}
.nf-tabs-form__checkbox {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
.nf-tabs-form__checkbox:checked ~ .nf-tabs-form__date .forTime {
  display: block;
}
.nf-tabs-form__checkbox:checked + .nf-tabs-form__label:before {
  transform: translate(-5px, -50%);
  background-color: var(--custom-button_color);
}
.nf-tabs-form__checkbox + .nf-tabs-form__label {
  cursor: pointer;
  position: relative;
  display: flex;
  align-items: center;
}
.nf-tabs-form__checkbox + .nf-tabs-form__label:before, .nf-tabs-form__checkbox + .nf-tabs-form__label:after {
  content: "";
  display: block;
}
.nf-tabs-form__checkbox + .nf-tabs-form__label:after {
  border-radius: 12px;
  height: 24px;
  width: 40px;
  flex: 0 0 40px;
  border: 1px solid var(--custom-font_color);
  margin-left: 12px;
}
.nf-tabs-form__checkbox + .nf-tabs-form__label:before {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: var(--custom-font_color);
  border-radius: 50%;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.15), 0 3px 1px rgba(0, 0, 0, 0.06);
  width: 18px;
  height: 18px;
  right: 0;
  transform: translate(-19px, -50%);
  transition: transform 0.25s;
}
.nf-tabs-form__checkbox ~ .nf-tabs-form__date .forTime {
  display: none;
}
.nf-tabs-form__label {
  display: block;
  margin: 0;
  font-size: var(--custom-font_size);
  color: var(--custom-font_color);
}
.nf-tabs-form-places {
  display: flex;
  align-items: center;
  position: relative;
}
.nf-tabs-form-places__input {
  display: block;
  width: 36px !important;
  flex: 0 0 36px;
  text-align: center;
  padding: 0;
  margin: 0;
  border: none !important;
}
.nf-tabs-form-places__control {
  cursor: pointer;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;
  border-radius: var(--custom-border_radius);
  color: var(--custom-font_color, #fff);
  border: 1px solid currentColor;
  width: 37px;
  flex: 0 0 37px;
  height: 39px;
  padding: 0;
  margin: 0;
  background-color: transparent;
}
.nf-tabs-form-places__control[disabled] {
  opacity: 0.3;
}
.nf-tabs-form-places__control:before {
  font-weight: 700;
  font-size: 1.5em;
  line-height: 1;
}
.nf-tabs-form-places__control[data-func=dec] {
  margin-left: 6px;
}
.nf-tabs-form-places__control[data-func=dec]:before {
  content: "-";
}
.nf-tabs-form-places__control[data-func=inc]:before {
  content: "+";
}
.nf-tabs-form__exchange {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: var(--custom-button_color);
  color: var(--custom-font_color);
  width: 56px;
  flex: 0 0 56px;
  height: 44px;
  border: none;
  outline: none;
  border-radius: var(--custom-border_radius);
  z-index: 2;
}
.nf-tabs-form__exchange svg {
  position: relative;
  display: block;
  width: 20px;
  height: 20px;
  transform: rotate(90deg);
}
.nf-tabs-form__date {
  display: flex;
  align-items: center;
  margin-top: 15px;
  border-radius: var(--custom-border_radius);
  border: 1px solid var(--custom-font_color);
  width: 100%;
  flex: 0 0 100%;
  min-height: 45px;
}
.nf-tabs-form__date .forDate {
  position: relative;
  width: 100%;
  flex: 1 0 0%;
}
.nf-tabs-form__date .forDate .additImageBlock {
  right: auto;
  left: 0;
  padding-right: 0;
  padding-left: 10px;
}
.nf-tabs-form__date .forTime {
  position: relative;
  width: 50%;
  flex: 0 0 50%;
}
.nf-tabs-form__date .forTime .additImageBlock {
  right: 0px;
}
.nf-tabs-form__date .forTime .additImageBlock:before {
  position: absolute;
  content: "XX:XX";
  right: calc(100% + 10px);
}
.nf-tabs-form__date .additImageBlock {
  color: var(--custom-font_color);
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.nf-tabs-form__date .additImageBlock .icon {
  display: block;
  width: 20px;
  height: 20px;
}
.nf-tabs-form__date input[type=text],
.nf-tabs-form__date input[type=date],
.nf-tabs-form__date input[type=time] {
  border: none;
  border-radius: 0;
}
.nf-tabs-form__date input[type=time] {
  padding-right: 40px;
  padding-left: 10px;
  text-align: right;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}
.nf-tabs-form__date input[type=time]:not(:placeholder-shown) ~ .additImageBlock:before {
  display: none;
}
.nf-tabs-form__date input[type=text],
.nf-tabs-form__date input[type=date] {
  padding-left: 40px;
}
.nf-tabs-form-select-dropdown.select2-dropdown {
  color: var(--custom-font_color);
  border-bottom-left-radius: var(--custom-border_radius);
  border-bottom-right-radius: var(--custom-border_radius);
  border-color: var(--custom-font_color);
  background-color: var(--custom-background_color);
}
.nf-tabs-form-select-dropdown.select2-dropdown .select2-search--dropdown .select2-search__field {
  border-color: var(--custom-font_color);
  background-color: var(--custom-background_color);
  outline: none !important;
}
.nf-tabs-form-select-dropdown.select2-dropdown .select2-results__option--selectable {
  background-color: transparent;
}
.nf-tabs-form-select-dropdown.select2-dropdown .select2-results__option--selectable.select2-results__option--highlighted {
  background-color: var(--custom-button_color);
  color: var(--custom-font_color_authorization_buttons);
}
.nf-tabs-form-select-dropdown.select2-dropdown .select2-results__option--selectable.select2-results__option--selected {
  display: none;
}
.nf-tabs-status {
  display: flex;
  width: 100%;
}
.nf-tabs-status__item {
  border: 1px solid var(--custom-font_color, #fff);
  border-radius: 3px;
  width: 100%;
  flex: 1 0 0%;
  padding: 20px;
  text-align: center;
}
.nf-tabs-status__item + .nf-tabs-status__item {
  margin-left: 12px;
}
.nf-tabs-submit {
  margin-top: 15px;
}
@media (min-width: 992px) {
  .nf-tabs-submit {
    display: flex;
    justify-content: center;
  }
}

.reservationsWindWrapp {
  position: relative;
}
.reservationsWindWrapp .datepicker.dropdown-menu {
  top: 50% !important;
  left: 50% !important;
  transform: translate(-50%, -50%) !important;
  right: auto !important;
  bottom: auto !important;
  min-width: 306px !important;
  background-color: var(--custom-primary_color, #575f6a) !important;
  border-color: var(--custom-font_color, rgba(0, 0, 0, 0.2)) !important;
  border-radius: var(--custom-border_radius, 5px) !important;
}
.reservationsWindWrapp .datepicker.dropdown-menu:before, .reservationsWindWrapp .datepicker.dropdown-menu:after {
  display: none !important;
}

.eva-iframe {
  --color-gray: #636b76;
  --color-gray1: #dde6ed;
  --color-green: #489b60;
  --color-blue: #0574bf;
  position: relative;
  font-family: "Montserrat", system-ui;
  font-optical-sizing: auto;
  width: 100%;
  max-width: 350px;
}
.eva-iframe-station {
  background: #fff;
  border-radius: 28px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.eva-iframe-station-item {
  display: flex;
  align-items: center;
  padding-left: 24px;
  padding-right: 24px;
}
.eva-iframe-station-item .icon {
  display: block;
  width: 32px;
  flex: 0 0 32px;
  height: 32px;
  margin-right: 8px;
}
.eva-iframe-station-item__input {
  width: 100%;
  opacity: 0;
  height: 43px;
}
.eva-iframe-station-item__input::placeholder {
  color: transparent;
}
.eva-iframe-station-item__input-wrapper {
  position: relative;
  width: 100%;
  flex: 1 0 0%;
}
.eva-iframe-station-item__input ~ .select2 .select2-selection {
  border: none;
  padding-top: 20px;
  height: 43px;
  background-color: #fff !important;
}
.eva-iframe-station-item__input ~ .select2 .select2-selection__rendered {
  line-height: 20px !important;
  padding: 0 !important;
}
.eva-iframe-station-item__input ~ .select2 .select2-selection__arrow {
  display: none;
}
.eva-iframe-station-item__input ~ .select2.selected ~ label {
  top: 0;
}
.eva-iframe-station-item__label {
  display: block;
  position: absolute;
  top: 11.5px;
  left: 0;
  pointer-events: none;
  margin-bottom: 0;
  color: var(--color-gray);
  font-weight: 500;
  font-size: 16px;
  line-height: 1.25;
  transition: 0.25s top;
}
.eva-iframe-station-separator {
  position: relative;
  display: flex;
  justify-content: flex-end;
  pointer-events: none;
  margin-top: -6px;
  margin-bottom: -6px;
}
.eva-iframe-station-separator:before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  height: 2px;
  background-color: var(--color-gray1);
}
.eva-iframe-station-separator__action {
  position: relative;
  display: block;
  cursor: pointer;
  outline: none;
  background: none;
  border: none;
  margin-right: 55px;
  padding-right: 4px;
  padding-left: 4px;
  background-color: #fff;
  pointer-events: auto;
}
.eva-iframe-station-separator__action .icon {
  display: block;
  width: 40px;
  height: 40px;
}
.eva-iframe-options {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  background-color: #fff;
  border-radius: 28px;
  margin-top: 8px;
}
.eva-iframe-options:before {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50);
  width: 2px;
  background-color: var(--color-gray1);
}
.eva-iframe-options:after {
  content: "";
  display: block;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  transform: translateY(-50);
  height: 2px;
  background-color: var(--color-gray1);
}
.eva-iframe-options-item {
  display: flex;
  align-items: center;
  width: 50%;
  flex: 0 0 50%;
  height: 79px;
  padding-left: 8px;
  padding-right: 8px;
  box-sizing: border-box;
}
.eva-iframe-options-item:nth-chil(n + 2) {
  margin-top: 2px;
}
.eva-iframe-options-item > .icon {
  display: block;
  width: 32px;
  flex: 0 0 32px;
  height: 32px;
  margin-right: 8px;
}
.eva-iframe-options-item__input {
  display: block;
  width: 100%;
  font-size: 18px;
  line-height: 23px;
  padding: 0;
  letter-spacing: 0.01em;
  border: none;
  box-sizing: border-box;
  font-family: inherit;
}
.eva-iframe-options-item__input:focus {
  outline: none;
}
.eva-iframe-options-item__input[type=time] {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  font-size: 28px;
  line-height: 36px;
  padding-top: 18px;
  background-color: #fff;
}
.eva-iframe-options-item__input[type=time]::-webkit-calendar-picker-indicator {
  background: none;
  cursor: pointer;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  padding: 0;
  margin: 0;
}
.eva-iframe-options-item__input[type=time] ~ label {
  top: 0;
}
.eva-iframe-options-item__input-wrapper {
  position: relative;
  width: 100%;
  flex: 1 0 0%;
}
.eva-iframe-options-item__label {
  display: block;
  position: absolute;
  top: 11.5px;
  left: 0;
  pointer-events: none;
  margin-bottom: 0;
  color: var(--color-gray);
  font-weight: 500;
  font-size: 14px;
  line-height: 1.2857142857;
  transition: 0.25s top;
}
.eva-iframe-options-countable {
  display: flex;
  align-items: center;
}
.eva-iframe-options-countable__control {
  color: var(--color-green);
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 0;
}
.eva-iframe-options-countable__control[disabled] {
  color: rgba(6, 6, 6, 0.25);
}
.eva-iframe-options-countable__control .icon {
  display: block;
  width: 30px;
  height: 30px;
}
.eva-iframe-options-countable__input {
  display: block;
  width: 100%;
  flex: 1 0 0%;
  height: 43px;
  outline: none;
  border: none;
  text-align: center;
  font-weight: 500;
  font-size: 28px;
  padding: 0;
  text-decoration: underline;
}
.eva-iframe-submit {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-top: 24px;
  border: none;
  outline: none;
  background-color: var(--color-blue);
  box-shadow: 4px 4px 12px 0px #78aeff inset;
  border-radius: 100px;
  height: 48px;
  width: 100%;
  color: #fff;
  font-size: 18px;
  font-weight: 500;
  text-transform: uppercase;
  cursor: pointer;
}
.eva-iframe-submit .icon {
  display: block;
  width: 32px;
  flex: 0 0 32px;
  height: 32px;
  margin-right: 16px;
  margin-left: -48px;
}
.eva-iframe .hidden {
  display: none;
}
.eva-iframe > .select2-container {
  position: static !important;
}
.eva-iframe > .select2-container .select2-dropdown {
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #010813;
  border-radius: 28px !important;
  border: none;
  padding: 10px;
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}
.eva-iframe > .select2-container .select2-dropdown .select2-search--dropdown .select2-search__field {
  border-color: #dde6ed;
  background-color: #fff;
  outline: none !important;
}
.eva-iframe > .select2-container .select2-dropdown .select2-results__option--selectable {
  background-color: transparent;
}
.eva-iframe > .select2-container .select2-dropdown .select2-results__option--selectable.select2-results__option--highlighted {
  background-color: #0574bf;
  color: #fff;
}
.eva-iframe > .select2-container .select2-dropdown .select2-results__option--selectable.select2-results__option--selected {
  display: none;
}

.reservationsWindWrapp_embedded {
  padding: 0;
  max-width: none;
  margin: 0;
}
.reservationsWindWrapp_embedded form {
  padding: 0;
}